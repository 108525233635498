import Vue from 'vue';
import plugin from '@vue/composition-api';
import './app.scss';
import './components';
import 'vue-scrollama/dist/vue-scrollama.css';
Vue.use(plugin);
(() => {
  const requestAnimationFrame =
    window.requestAnimationFrame ||
    (window as any).mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    (window as any).msRequestAnimationFrame;
  window.requestAnimationFrame = requestAnimationFrame;
})();
