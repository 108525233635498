
































import Vue from 'vue';
import axios from 'axios';
import { ref, reactive } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { data } from '@/observable';
import { mdiAccount } from '@mdi/js';
import { vRequired, vEmail } from 'monkey-validates-form';
export default Vue.extend({
    props: {
        horizontal: Boolean,
    },
    setup(props, {emit}) {
        const formulario = ref(null);
        const claseContenedor = (props.horizontal) ? '_flex _justify-between _items-end md:_flex-wrap sm:_flex-wrap xsm:_flex-wrap' : '_container _mx-auto';
        const claseBoton = (props.horizontal) ? '_mt-6 _w-6/12 md:_w-full sm:_w-full xsm:_w-full _flex _justify-end' : '_mt-8';
        const claseForm = (props.horizontal) ? '_w-6/12 md:_w-full sm:_w-full xsm:_w-full' : '';

        const respuesta = reactive({
            texto: '',
            type: 'info',
            visible: false,
            spinner: false,
        });

        const state: any = reactive({
            form: 'suscripcion',
            email: '',
        });

        const close = () => emit('close');

        const enviar = (e: Event) => {
            e.preventDefault();
            if ((formulario.value as any).validate()) {
                respuesta.spinner = true;
                const formdata = new FormData();
                const config = {
                headers: { 'Content-Type': 'multipart/form-data' },
                };
                for (const key in state) {
                    formdata.append(key, state[key]);
                }
                axios.post('/sendinformation.ts', formdata, config)
                .then((response) => {
                    (formulario.value as any).reset();
                    respuesta.texto = 'Tu suscripción se ha registrado con éxito.';
                    respuesta.type = 'success';
                }).catch((error) => {
                    respuesta.texto = 'Hubo un error al procesar la solicitud. Por favor vuelve a intentarlo.';
                    respuesta.type = 'error';
                }).finally(() => {
                    respuesta.visible = true;
                    respuesta.spinner = false;
                });
            }
        };

        return {
            formulario,
            respuesta,
            state,
            enviar,
            claseContenedor,
            claseBoton,
            claseForm,
            vRequired,
            vEmail,
        };
    },
});
