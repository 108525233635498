import Vue from 'vue';
import Vuetify, { VApp, VWindow, VWindowItem, VDialog, VCarousel, VCarouselItem, VCard, VCardActions, VCardTitle, VCardText, VDivider, VBtn, VSpacer, VParallax, VForm, VProgressCircular, VAlert, VTextField, VSelect, VTextarea } from 'vuetify/lib';
import es from 'vuetify/src/locale/es';
Vue.use(Vuetify, {
  components: {
    VApp,
    VWindow,
    VWindowItem,
    VDialog,
    VCarousel,
    VCarouselItem,
    VCard,
    VCardActions,
    VCardTitle,
    VCardText,
    VDivider,
    VBtn,
    VSpacer,
    VParallax,
    VForm,
    VProgressCircular,
    VAlert,
    VTextField,
    VSelect,
    VTextarea,
  },
});
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#ee44aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
