import Vue from 'vue';
import store from '@/store';
import { onMounted, ref, reactive, computed } from '@vue/composition-api';
import vuetify from '@/config/plugins/vuetify';
import { vRequired, vEmail, vPhoneRules } from 'monkey-validates-form';
import { Debug } from '@/shared/utils/helpers';
import axios from 'axios';

const FooterSection = Vue.extend({
    setup() {

        // const contacto = computed(() => store.state.contacto);
        // const formContacto = ref(null);
        // const valid = ref(false);
        // const state: any = reactive({
        //     form: 'contacto',
        //     nombre: '',
        //     email: '',
        //     telefono: '',
        //     tipoproyecto: '',
        //     requerimiento: '',
        // });

        // const reglas = reactive({
        //     nombre: [vRequired],
        //     email: [vRequired, vEmail],
        //     telefono: [vRequired, vPhoneRules],
        //     tipoproyecto: [vRequired],
        //     requerimiento: [vRequired],
        // });

        // const respuesta = reactive({
        // texto: '',
        // type: 'error',
        // visible: false,
        // spinner: false,
        // });

        // const lista = ref([
        // 'Proyecto Tribeca',
        // 'Proyecto 281',
        // 'Diseño residencial',
        // 'Diseño de oficinas',
        // 'Diseño comercial',
        // ]);

        const close = () => store.commit('toggleContactForm');

        const android = ref(false);
        const ios = ref(false);
        const appleDig = ref(false);
        const adrDig = ref(false);
        const fbApple = () => {
            appleDig.value = !appleDig.value;
        };
        const fbAndr = () => {
            adrDig.value = !adrDig.value;
        };

        onMounted (() => {
            const userAgent = navigator.userAgent || navigator.vendor;
            if (/android/i.test(userAgent)) {
                android.value = true;
            } else if (/iPad|iPhone|iPod/.test(userAgent)) {
                ios.value = true;
            } else {
                android.value = false;
                ios.value = false;
            }
        });

        const dibujar = ref(false);
        const terms = ref(false);
        const ToggleTerms = () => {
            terms.value = !terms.value;
        };
        const handleScroll = () => {
            if (window.screen.width <= 600 && window.screen.height <= 960) {
                if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500)) {
                    dibujar.value = true;
                }
            } else {
                if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 180)) {
                    dibujar.value = true;
                }
            }
        };
        document.addEventListener('scroll', handleScroll);
        const toggleForm = () => {
            store.commit('toggleContactForm');
        };

        // const enviar = (e: Event) => {
        //     // e.preventDefault();
        //     if ((formContacto.value as any).validate()) {
        //       respuesta.spinner = true;
        //       const formdata = new FormData();
        //       const config = {
        //         headers: { 'Content-Type': 'multipart/form-data' },
        //       };
        //       for (const key in state) {
        //           formdata.append(key, state[key]);
        //       }
        //       axios.post('https://rivayo.com/assets/php/sendemail.php', formdata, config)
        //           .then((response) => {
        //               (formContacto.value as any).reset();
        //               respuesta.texto = response.data.mensaje;
        //               respuesta.type = 'success';
        //           }).catch((error) => {
        //               respuesta.texto = 'Error al enviar el mensaje';
        //               respuesta.type = 'error';
        //           }).finally(() => {
        //               respuesta.visible = true;
        //               respuesta.spinner = false;
        //           });
        //     }
        //   };

        return {
            dibujar,
            toggleForm,
            terms,
            ToggleTerms,
            android,
            ios,
            appleDig,
            adrDig,
            fbApple,
            fbAndr,
            // lista,
            // close,
            // state,
            // reglas,
            // valid,
            // respuesta,
            // contacto,
            // formContacto,
            // vRequired,
            // vEmail,
            // vPhoneRules,
            // enviar,
        };
    },
});
export default new FooterSection({
    el: '#footer',
    vuetify,
    delimiters: ['[{', '}]'],
});
