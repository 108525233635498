






import Vue from 'vue';
import { onMounted, ref } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { data } from '@/observable';
import { mdiAccount } from '@mdi/js';
export default Vue.extend({
  name: 'Test',
  props: {
    data: {
      type: Object,
      // default: () => ({}),
    },
  },
  setup(props) {
    return {
    };
  },
});
