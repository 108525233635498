import Vue from 'vue';
import { onMounted, ref, computed } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { toggles } from '@/observable';
import bodymovin from 'lottie-web';

const CargaSection = Vue.extend({
    setup() {
        const listo = computed( () => {
            return toggles.listo;
        });
        onMounted(() => {
            Debug.log('281 mounted');
        });
        return {
            listo,
        };
    },
});
export default new CargaSection({
    el: '#carga',
    delimiters: ['[{', '}]'],
});
