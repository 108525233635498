import Vue from 'vue';

export const data = Vue.observable({
    counter: 0,
});

export const toggles = Vue.observable({
    menu: false,
    listo: false,
});
