















import Vue from 'vue';
import { onMounted, ref, createComponent } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { mdiAccount } from '@mdi/js';
import bodymovin from 'lottie-web';
export default createComponent({
    props: {
        puntos: Boolean,
        punto: Object,
    },
    setup(props, {emit}) {
        const data: any = ref(props.punto);
        const close = () => {
            emit('close');
        };
        onMounted(() => {
            const element = data.value.anime.value;
            const pat = data.value.path.value;
            const contenedor = document.getElementById(element);
            if (contenedor) {
                bodymovin.loadAnimation({
                    container: contenedor,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: pat,
                });
            }
        });
        return {
            close,
            data,
        };
    },
});
