






































































import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import { onMounted, ref, reactive, computed } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { data } from '@/observable';
import { mdiAccount } from '@mdi/js';
import { vRequired, vEmail, vPhoneRules } from 'monkey-validates-form';
export default Vue.extend({
  props: {
    info: Object,
  },
  setup(props, { emit }) {
    const contacto = computed(() => store.state.contacto);
    const i = props.info;
    const formContacto = ref(null);
    const valid = ref(false);
    const state: any = reactive({
      form: 'contacto',
      nombre: '',
      email: '',
      telefono: '',
      tipoproyecto: '',
      requerimiento: '',
    });

    const reglas = reactive({
      nombre: [vRequired],
      email: [vRequired, vEmail],
      telefono: [vRequired, vPhoneRules],
      tipoproyecto: [vRequired],
      requerimiento: [vRequired],
    });

    const respuesta = reactive({
      texto: '',
      type: 'error',
      visible: false,
      spinner: false,
    });

    const lista = ref([
      'Proyecto Tribeca',
      'Proyecto 281',
      'Diseño residencial',
      'Diseño de oficinas',
      'Diseño comercial',
    ]);

    const close = () => store.commit('toggleContactForm');

    const enviar = (e: Event) => {
      e.preventDefault();
      if ((formContacto.value as any).validate()) {
        respuesta.spinner = true;
        const formdata = new FormData();
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        for (const key in state) {
            formdata.append(key, state[key]);
        }
        axios.post('/sendinformation.ts', formdata, config)
            .then((response) => {
                respuesta.texto = `${state.nombre} gracias por escribirnos, pronto nos prondremos en contacto contigo.`;
                respuesta.type = 'success';
                (formContacto.value as any).reset();
            }).catch((error) => {
                respuesta.texto = `${state.nombre} te ofrecemos una disculpa, hubo un error al enviar el mensaje. Por favor vuelve a intentarlo.`;
                respuesta.type = 'error';
            }).finally(() => {
                respuesta.visible = true;
                respuesta.spinner = false;
            });
      }
    };

    return {
      i,
      lista,
      close,
      enviar,
      state,
      reglas,
      valid,
      respuesta,
      contacto,
      formContacto,
    };
  },
});
