import Vue from 'vue';
import { onMounted, ref, computed } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { toggles } from '@/observable';
import vuetify from '@/config/plugins/vuetify';

const MenuSection = Vue.extend({
    setup() {
        const android = ref(false);
        const ios = ref(false);
        const girar = ref(false);
        const appleDig = ref(false);
        const adrDig = ref(false);
        const menu = computed( () => {
            return toggles.menu;
        });
        const fbApple = () => {
            appleDig.value = !appleDig.value;
        };
        const fbAndr = () => {
            adrDig.value = !adrDig.value;
        };
        const toggleMenu = () => {
            toggles.menu = !toggles.menu;
        };
        onMounted (() => {
            const userAgent = navigator.userAgent || navigator.vendor;
            if (/android/i.test(userAgent)) {
                android.value = true;
            } else if (/iPad|iPhone|iPod/.test(userAgent)) {
                ios.value = true;
            } else {
                android.value = false;
                ios.value = false;
            }

            if (window.localStorage.getItem('aviso') === null) {
                setTimeout(() => {
                    if (/iPad/.test(userAgent) && window.innerHeight > window.innerWidth) {
                        girar.value = true;
                    } else {
                        girar.value = false;
                    }
                }, 3000);
                window.localStorage.setItem('aviso', 'false');
            } else {
                window.localStorage.setItem('aviso', 'false');
            }
        });
        return {
            menu,
            toggleMenu,
            android,
            ios,
            girar,
            fbApple,
            appleDig,
            fbAndr,
            adrDig,
        };
    },
});
export default new MenuSection({
    el: '#menu',
    vuetify,
    delimiters: ['[{', '}]'],
});
