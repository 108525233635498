









































import Vue from 'vue';
import axios from 'axios';
import { ref, reactive, onMounted } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { data } from '@/observable';
import { mdiAccount } from '@mdi/js';
import { vRequired, vEmail } from 'monkey-validates-form';
import ZoomScroll from 'vue-zoom-scroll';
import Scrollama from 'vue-scrollama';
export default Vue.extend({
    props: {
    },
    components: {ZoomScroll, Scrollama},
    setup() {
        const zoomScrollDetails = ref({});
        const opcak = ref(0);
        const wh = ref(0);
        const zoom = (e: any) => {
            opcak.value = (0.2);
        };
        const currStepId = ref(null);
        const steps = [
            {id: '1'},
            {id: '2'},
            {id: '3'},
        ];
        const stepEnterHandler = ({element, direction, index}: any) => {
            currStepId.value = element.dataset.stepId;
        };
        onMounted(() => {
            wh.value = window.innerHeight + 1083;
        });
        return {
            currStepId,
            steps,
            stepEnterHandler,
            zoom,
            opcak,
            wh,
        };
    },
});
